/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";
import Celebrate from "@/components/Celebrate";
import RequireAuthWrapper from "@/components/RequireAuthWrapper";
import { Tasks } from "@/components/TaskContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { useEffect } from "react";

const LEADERBOARD = process.env.NEXT_PUBLIC_LEADERBOARD_URL;

export default function Home() {
  const { scores, tasks, event } = Tasks();

  useEffect(() => {
    if (event?.name) {
      document.title = `${event.name} - Welcome`;
    }
  }, [event?.name]);

  return (
    <RequireAuthWrapper>
      {scores && <Celebrate />}
      <Box sx={{ width: "100%", maxWidth: "lg" }}>
        <Typography variant="h1">{event?.name || "Welcome!"}</Typography>
        <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
          Not sure where to begin? Start with{" "}
          <Link href="/task/0-registration">Registration</Link> and get some quick
          points on{" "}
          <Link href={LEADERBOARD!} target="_blank">
            the Leaderboard
          </Link>
          .
        </Typography>
        <Typography variant="h2">Tasks</Typography>
        <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
          Most tasks can be completed in any order, but we recommend you start
          with the <Link href="/task/1-intro">Intro Task first</Link>.
        </Typography>
        <ul>
          {tasks &&
            [...tasks.keys()].map((id) => {
              const task = tasks.get(id);
              if (task?.hidden || !task?.enabled) {
                return null;
              }
              return (
                <li key={id}>
                  <Link href={`/task/${id}`}>{task.name}</Link> -{" "}
                  {task.description || ""} - Score:{" "}
                  {scores?.task_totals.get(task.id) || 0}
                </li>
              );
            })}
        </ul>
      </Box>
    </RequireAuthWrapper>
  );
}
